import React from "react";
import { graphql, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import SEO from "../components/seo";
import { Container } from "../components/container";
import { Button } from "../components/button";
import { Nav } from "../components/nav";
import { Footer } from "../components/footer";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}: {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        endLinkTo?: string;
        image?: {
          childImageSharp: { fluid: FluidObject };
        };
      };
      excerpt: string;
      html: string;
    };
  };
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const image = frontmatter.image;

  return (
    <div className="flex flex-col min-h-screen">
      <SEO title={frontmatter.title} description={markdownRemark.excerpt} />
      <div>
        <Nav />
      </div>
      {image ? (
        <div className="overflow-hidden lg:hidden">
          <Img fluid={image.childImageSharp.fluid} />
        </div>
      ) : null}
      <Container className="flex-grow w-full mb-12">
        <main className="markdown">
          <div className="lg:grid grid-cols-3 gap-2">
            <h1 className="self-center col-span-1">{frontmatter.title}</h1>
            {image ? (
              <div className="hidden lg:block col-span-2 rounded-lg overflow-hidden">
                <Img fluid={image.childImageSharp.fluid} />
              </div>
            ) : null}
          </div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </main>
        {frontmatter.endLinkTo ? (
          <Button size="large">
            <Link to={frontmatter.endLinkTo}>Read more</Link>
          </Button>
        ) : null}
      </Container>
      <Footer />
    </div>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        path
        endLinkTo
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
